.tile-grid {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.tile-link {
}

.tile-card {
  min-width: 15rem;
  margin: 0.5rem;
  text-align: center;
}

.tile-card-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
