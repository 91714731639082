.form-card {
  display: flex;
  width: 100%;
  max-width: 500px;
}

.form-element {
  margin-top: 1rem;
  margin-bottom: 1rem;
  height: 50px;
}

.form-element-long {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
