.app-logo {
  margin-bottom: 1.5rem;
}

.app-subscribe-button {
  margin-top: 1rem;
  margin-bottom: 3rem;
}

.youtube-logo {
  margin-right: 0.75rem;
}
